<template>
    <select
            v-model="timeZonesSelect"
            class="select-zone"
    >
        <option
                v-for="zone in $options.TIME_ZONES"
                :key="zone.name"
                :value="zone.value"
        >{{zone.name}}</option>
    </select>
</template>

<script>
  import {TIME_ZONES} from '../../../const';
  export default {
    name: "TimeZoneSelect",
    data(){
      return {
        timeZonesSelect: '',
      }
    },
    TIME_ZONES,
    watch:{
      timeZonesSelect(newZone){
        this.$emit('zone-selected', newZone)
      }
    },
    mounted() {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const localTimeExist = TIME_ZONES.find(({value}) => value === localTimeZone)?.value
      if (localTimeExist){
        this.timeZonesSelect = localTimeExist
      } else {
        this.timeZonesSelect = TIME_ZONES[0].value
      }
    }
  }
</script>

<style scoped>
    .select-zone{
        text-transform: uppercase;
    }
</style>